@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

html,
body,
#root,
.app {

  width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 13px;
}

/* @media (min-width: 600px) {
  .css-hyum1k-MuiToolbar-root {
      min-height: 50px !important;
  }
} */
.row{
  width: 100%;
  display: flex;
}
.col1{
  width: 50%;
}

.col2{
  width: 50%;
}

.column1{
  width: 33%;
}

.column2{
  width: 33%;
}
.column3{
  width: 33%;
}
.upload-input {
  position: relative;
  text-align: right;
  opacity: 0;
  z-index: 2;
  left: 53px;
  cursor: pointer;

  width: 68%;
  height: 100%;
  top: 22px;
}

.upload-btn {
  top: -9px;
  left: 53px;
  width: 68%;
  height: 100%;
  z-index: 1;
}
.dummyColumn{
  width: 81%
}