
* {
    margin: 0px;

}
.swal2-container {
    z-index: 20000 !important;
}

.css-r1tbz4-MuiInputBase-root-MuiOutlinedInput-root .Mui-focused {
    border-color: 'blue' !important;
  }

  .css-nbhvpc-MuiTableCell-root  {
    padding: 10px!important;
  }
  .css-3lj95e-MuiTableCell-root{
    padding: 10px!important;
  }
 
  .tb {
    font-size: 14px !important;
}
.summary {
 
  width: fit-content !important;
}
.tableBorder{
  border: 1px solid #cdcdcd !important;
  font-size: 14px !important;
}